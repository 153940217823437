import { Link } from "react-router-dom";
import { PRIVACY_POLICY_ARTICLE_CLASS_NAME } from "../../../../core/constants/className";
import { ABOUT_ROUTE } from "../../../../core/constants/route";
import {
    COMPANY_ADDRESS_LINE_1,
    COMPANY_ADDRESS_LINE_2,
    COMPANY_BUSINESS_ID,
    COMPANY_EMAIL,
    COMPANY_NAME,
    COMPANY_PHONE_NUMBER_DISPLAY,
    COMPANY_PHONE_NUMBER_FULL,
    PRIVACY_HANDLER_EMAIL,
    PRIVACY_HANDLER_NAME,
    PRIVACY_HANDLER_PHONE_DISPLAY,
    PRIVACY_HANDLER_PHONE_FULL,
} from "../../../../core/constants/company";

export function PrivacyPolicyArticle () {
    return (
        <article className={ PRIVACY_POLICY_ARTICLE_CLASS_NAME }>

            <h1>Rekisteriseloste</h1>

            <h2>1. Rekisterinpitäjä</h2>

            <p><Link to={ ABOUT_ROUTE }>{ COMPANY_NAME }</Link><br />
                { COMPANY_ADDRESS_LINE_1 }<br />
                { COMPANY_ADDRESS_LINE_2 }<br />
                <br />
                { PRIVACY_HANDLER_NAME } | <a href={ "mailto:" + PRIVACY_HANDLER_EMAIL }>{ PRIVACY_HANDLER_EMAIL }</a> | <a href={ "tel:" + PRIVACY_HANDLER_PHONE_FULL }>{ PRIVACY_HANDLER_PHONE_DISPLAY }</a><br />
                <br />
                                         Asiakaspalvelu | <a href={ "mailto:" + COMPANY_EMAIL }>{ COMPANY_EMAIL }</a> | <a href={ "tel:" + COMPANY_PHONE_NUMBER_FULL }>{ COMPANY_PHONE_NUMBER_DISPLAY }</a><br />
                <br />
                                         Y-tunnus: { COMPANY_BUSINESS_ID }<br />
            </p>

            <h2>2. Rekisteröidyt</h2>

            <p>Rekisterissämme on asiakastietojamme ja verkkopalveluihimme rekisteröityneitä käyttäjiä.</p>

            <h2>3. Henkilötietojen käyttötarkoitus</h2>

            <p>Henkilötietoja käsitellään rekisteröidyn asiakassuhteen perusteella palveluidemme ja tuotteidemme myyntiä sekä markkinointia varten sekä näihin liittyvien verkkopalveluidemme käyttäjien tunnistamiseksi.</p>

            <h2>4. Rekisteriin tallennettavat henkilötiedot</h2>

            <p>Käyttäjärekisteri sisältää seuraavat tiedot:</p>

            <ul>
                <li>Käyttäjän nimi</li>
                <li>Sähköpostiosoite</li>
                <li>Puhelinnumero</li>
            </ul>

            <p>Asiakasrekisteri sisältää seuraavat tiedot:</p>

            <ul>
                <li>Yhteyshenkilön nimi</li>
                <li>Sähköpostiosoite</li>
                <li>Puhelinnumero</li>
                <li>Osoitetiedot</li>
            </ul>

            <p>Asiakasrekisteri sisältää myös tiedot ostetuista tuotteista tai palveluista.</p>

            <h2>5. Rekisteröidyn oikeudet</h2>

            <p>Rekisteröidyllä on seuraavat oikeudet, joiden käyttämistä koskevat pyynnöt tulee tehdä yllämainittuihin rekisterinpitäjän yhteystietoihin.</p>

            <p><strong>Tarkastusoikeus.</strong><br />
                Rekisteröity voi tarkistaa tallentamamme henkilötiedot.</p>

            <p><strong>Oikeus tietojen oikaisemiseen.</strong><br />
                Rekisteröity voi pyytää oikaisemaan häntä koskevat virheelliset tai puutteelliset tiedot.</p>

            <p><strong>Vastustamisoikeus.</strong><br />
                Rekisteröity voi vastustaa henkilötietojen käsittelyä, mikäli kokee, että henkilötietoja on käsitelty lainvastaisesti.</p>

            <p><strong>Suoramarkkinointikielto.</strong><br />
                Rekisteröidyllä on oikeus kieltää tietojen käyttäminen suoramarkkinointiin.</p>

            <p><strong>Poisto-oikeus.</strong><br />
                <br />
                Rekisteröidyllä on oikeus pyytää tietojen poistamista, jos tietojen käsittely ei ole tarpeen. Käsittelemme poistopyynnön, jonka jälkeen joko poistamme tiedot tai ilmoitamme perustellun syyn, miksi tietoja ei voida poistaa.<br />
                <br />
                On huomioitava, että rekisterinpitäjällä voi olla lakisääteinen tai muu oikeus olla poistamatta pyydettyä tietoa. Rekisterinpitäjällä on velvollisuus säilyttää kirjanpitoaineisto Kirjanpitolaissa (luku 2, 10 §) määritellyn ajan (10 vuotta) mukaisesti. Tämän vuoksi kirjanpitoon liittyvää aineistoa ei voida poistaa ennen määräajan umpeutumista.
            </p>

            <p><strong>Suostumuksen peruuttaminen.</strong><br />
                <br />
                Jos rekisteröityä koskeva henkilötietojen käsittely perustuu ainoastaan suostumukseen, eikä esim. asiakkuuteen tai jäsenyyteen, voi rekisteröity peruuttaa suostumuksen.<br />
                <br />
                Rekisteröity voi valittaa päätöksestä tietosuojavaltuutetulle.<br />
                <br />
                Rekisteröidyllä on oikeus vaatia, että rajoitamme kiistanalaisten tietojen käsittelyä siksi aikaa, kunnes asia saadaan ratkaistua.
            </p>

            <p><strong>Valitusoikeus.</strong><br />
                <br />
                Rekisteröidyllä on oikeus tehdä tietosuojavaltuutetulle kantelu, jos hän kokee, että rikomme henkilötietoja käsitellessämme voimassa olevaa tietosuojalainsäädäntöä.
            </p>

            <h2>6. Säännönmukaiset tietolähteet</h2>

            <p>Tietoja kerätään käyttäjältä itseltään verkkosivujemme käytön yhteydessä, tuotteiden tai palveluiden tilauksen yhteydessä, tai muihin verkkopalveluihimme rekisteröinnin yhteydessä.</p>

            <h2>7. Säännönmukaiset tietojen luovutukset</h2>

            <p>Tietoja ei luovuteta markkinointitarkoituksiin ulkopuolisille.<br />
                <br />
               Olemme varmistaneet, että kaikki palveluntarjoajamme noudattavat tietosuojalainsäädäntöä.</p>

            <h2>8. Käsittelyn kesto</h2>

            <p>Henkilötietoja käsitellään pääsääntöisesti niin kauan kuin asiakkuus on voimassa.<br />
                <br />
               Markkinointilistoiltamme pääsee pois poistamalla verkkopalvelustamme tilin tai asettamalla markkinointiviestit tilin asetuksista pois päältä.
            </p>

            <h2>9. Henkilötietojen käsittelijät</h2>

            <p>Rekisterinpitäjä ja tämän työntekijät käsittelevät henkilötietoja.<br />
                <br />
               Voimme myös ulkoistaa henkilötietojen käsittelyn osittain kolmannelle osapuolelle, jolloin takaamme sopimusjärjestelyin, että henkilötietoja käsitellään voimassa olevan tietosuojalainsäädännön mukaisesti ja muutoin asianmukaisesti.
            </p>

            <h2>10. Tietojen siirto EU:n ulkopuolelle</h2>

            <p>Henkilötietoja ei siirretä EU:n tai Euroopan talousalueen ulkopuolelle.</p>

            <h2>11. Automaattinen päätöksenteko ja profilointi</h2>

            <p>Emme käytä tietoja automaattiseen päätöksentekoon tai profilointiin.</p>

        </article>
    );
}
