/**
 * Open mobile menu
 * get hooks(two-way information), boolean value mobilemenu open or close
 * return button and boolean value mobilemenu open or close
 */

import { useCallback } from "react";
import { VoidCallback } from "../../../../../../io/hyperify/core/interfaces/callbacks";
import { LogService } from "../../../../../../io/hyperify/core/LogService";
import "./MobileMenuToggle.scss";

const LOG = LogService.createLogger('MobileMenuToggle');

export interface MobileMenuToggleProps {
    readonly isThisOpen?: boolean;
    readonly toggleMenu: VoidCallback;
}

export function MobileMenuToggle (props: MobileMenuToggleProps) {
    const isMenuOpen = props?.isThisOpen;
    const toggleMenu = props?.toggleMenu;
    const toggleMenuCallback = useCallback(
        () => {
            LOG.debug(`Toggling menu through prop`);
            toggleMenu();
        },
        [
            toggleMenu
        ]
    );
    return (
        <button className={"mobile-menu-toggle"}>
            <input
                type="checkbox"
                id="overlay-input-mobile"
                checked={isMenuOpen}
                onChange={() => {
                }}
            />
            <label
                htmlFor="overlay-input-mobile"
                id="overlay-button"
                onClick={toggleMenuCallback}
            >
                <span> </span>
            </label>
        </button>
    );
}
