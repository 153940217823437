/*
 * Note! Make sure you add any new route to the `ALL_REACT_ROUTES` below!
 */

import { ProductType } from "../../../../io/hyperify/core/store/types/product/ProductType";
import { MenuItem } from "../types/MenuItem";
import { SendanorProductId } from "../types/SendanorProductId";
import { encodeSendanorSeoImageData, SendanorSeoImageData } from "../types/SendanorSeoImageData";
import {
    T_NAV_ATLAS,
    T_NAV_CUSTOMER_SERVICE,
    T_NAV_DOMAINS,
    T_NAV_EMAIL,
    T_NAV_IMAGES,
    T_NAV_NET,
    T_NAV_OUTLET,
    T_NAV_SHELLS,
    T_NAV_SOFTWARE,
    T_NAV_VIRTUALSERVERS,
    T_NAV_WEBHOTELS,
    T_NAV_WEBRTC,
    T_NAV_WP,
} from "./translation";

/**
 * This must be the same name as inside the public folder.
 */
export const INDEX_FILE                       = '/index.html';

export const ABOUT_ROUTE                      = '/about';
export const INTRO_ROUTE                      = '/intro';
export const PRIVACY_POLICY_ROUTE             = '/privacy';
export const SOFTWARE_ROUTE                  = '/software';
export const WEBHOTELS_ROUTE                  = '/webhotels';
export const WEBHOTELS_FOOTER_ROUTE                  = '/webhotels/footer';
export const WEBHOTELS_EXTRA_ROUTE                  = '/webhotels/extra';
export const FOOTER_ROUTE                  = '/footer';
export const SOFTWARE_FOOTER_ROUTE             = '/software/footer';
export const VIRTUALSERVERS_INTRO_ROUTE             = '/virtualservers/intro';
export const VIRTUALSERVERS_ROUTE             = '/virtualservers';
export const VIRTUALSERVERS_PRODUCT_ROUTE             = '/virtualservers/products';
export const VIRTUALSERVERS_EXTRA_ROUTE             = '/virtualservers/extra';
export const VIRTUALSERVERS_FAQ_ROUTE             = '/virtualservers/faq';
export const VIRTUALSERVER_FOOTER_ROUTE                      = '/virtualservers/footer';
export const VIRTUALSERVERS_QUERY_PARAM_PRICE_TYPE             = 't';
export const VIRTUALSERVERS_QUERY_PARAM_PRODUCT_ID             = 'p';
export const VIRTUALSERVERS_EXTRA_ROUTE_FORMAT     = '/virtualservers/extra/:product';
export const VIRTUAL_SERVER_EXTRA_ROUTE                     = (product: string) : string => `/virtualservers/extra/${q(product)}`;

export const WEB_HOTEL_EXTRA_ROUTE_FORMAT     = '/webhotels/extra/:product';
export const WEB_HOTEL_EXTRA_ROUTE                     = (product: string) : string => `/webhotels/extra/${q(product)}`;

export const DOMAIN_SALE_ROUTE_FORMAT     = '/domains/sale/:product';
export const DOMAIN_SALE_ROUTE                     = (domain: string) => `/domains/sale/${q(domain)}`;

export const OUTLET_PRODUCT_ROUTE_FORMAT     = '/outlet/:product';
export const OUTLET_PRODUCT_ROUTE                     = (id: string) => `/outlet/${q(id)}`;

export const SOFTWARE_QUERY_PARAM_PRICE_TYPE             = 't';

export const SHELLS_QUERY_PARAM_PRICE_TYPE             = 't';
export const SHELLS_ROUTE                     = '/shells';
export const SHELLS_EXTRA_ROUTE                     = '/shells/extra';
export const SHELLS_FEATURE_LIST_ROUTE                     = '/shells/features';
export const SHELLS_FOOTER_ROUTE                     = `/shells/footer`;

export const ATLAS_ROUTE                     = '/atlas';
export const ATLAS_QUERY_PARAM_PRICE_TYPE             = 't';
export const ATLAS_EXTRA_ROUTE_FORMAT     = '/atlas/extra/:product';
export const ATLAS_EXTRA_ROUTE                     = (product: SendanorProductId) => `/atlas/extra/${q(product)}`;

export const OUTLET_PRODUCT_QUERY_PARAM_PRICE_TYPE             = 't';
export const OUTLET_PRODUCT_LIST_ROUTE                    = '/outlet';

export const DOMAIN_SALE_PRODUCT_QUERY_PARAM_PRICE_TYPE             = 't';
export const ATLAS_EXTRA_PRODUCT_QUERY_PARAM_PRICE_TYPE             = 't';
export const VIRTUAL_SERVER_EXTRA_PRODUCT_QUERY_PARAM_PRICE_TYPE             = 't';
export const WEB_HOTEL_EXTRA_PRODUCT_QUERY_PARAM_PRICE_TYPE             = 't';
export const EMAIL_EXTRA_PRODUCT_QUERY_PARAM_PRICE_TYPE             = 't';
export const CUSTOMER_SERVICE_ROUTE           = '/customerservice'
export const CART_ROUTE                       = '/cart'
export const NEWSLETTERS_ROUTE                = '/newsletters';
export const DOMAINS_ROUTE                    = '/domains';
export const DOMAINS_SALE_LIST_ROUTE                    = '/domains/sale';
export const DATABASES_ROUTE                  = '/databases';

export const EMAILS_ROUTE                                       = '/emails';
export const EMAILS_EXTRA_PRODUCT_LIST_ROUTE                    = '/emails/extra';
export const EMAILS_EXTRA_ROUTE_FORMAT                         = '/emails/extra/:product';
export const EMAILS_EXTRA_ROUTE                                 = ( product: string) : string => `/emails/extra/${q(product)}`;
export const EMAILS_FOOTER_ROUTE                                = '/emails/footer';

export const MY_INDEX_ROUTE                   = '/my';
export const MY_CLIENT_LIST_ROUTE             = '/my/clients';
export const MY_LOGIN_ROUTE                   = '/my/login';
export const MY_CLIENT_ROUTE                  = '/my/clients/:clientId';
export const MY_CLIENT_PAYMENT_LIST_ROUTE     = '/my/clients/:clientId/payments';
export const MY_CLIENT_INVENTORY_LIST_ROUTE   = '/my/clients/:clientId/inventory';
export const MY_CLIENT_INVENTORY_ITEM_ROUTE   = '/my/clients/:clientId/inventory/:inventoryItemId';
export const MY_CLIENT_INVOICE_LIST_ROUTE     = '/my/clients/:clientId/invoices';
export const MY_CLIENT_INVOICE_ROUTE          = '/my/clients/:clientId/invoices/:invoiceId';
export const MY_CLIENT_PAYMENT_ROUTE          = '/my/clients/:clientId/payments/:paymentId';
export const MY_CLIENT_ORDER_LIST_ROUTE       = '/my/clients/:clientId/orders';
export const MY_CLIENT_ORDER_ROUTE            = '/my/clients/:clientId/orders/:orderId';
export const NEW_ORDER_ROUTE                  = '/my/order';
export const TERMS_OF_USE_ROUTE               = '/termsofuse';
export const TERMS_OF_DELIVERY_ROUTE          = '/termsofdelivery';
export const COOKIES_ROUTE                    = '/cookies';
export const WEBRTC_ROUTE                     = '/webrtc';
export const WORDPRESS_ROUTE                  = '/wp';
export const PREMIUM_DOMAINS_ROUTE            = '/premium';
export const IMAGES_ROUTE                     = '/images';
export const NET_ROUTE                        = '/net';
export const OUTLET_ROUTE                        = '/outlet';
export const LOGIN_INDEX_ROUTE                = '/login';
export const AGENT_FACEBOOK_ROUTE                    = '/agent/facebook';
export const INDEX_ROUTE                      = '/';

export const ROUTE_RULES = [
    { route : SHELLS_ROUTE,           enableIfProductsOf : [ ProductType.SHELL, ProductType.SHELL_EXTRA ] },
    { route : ATLAS_ROUTE,            enableIfProductsOf : [ ProductType.ATLAS, ProductType.ATLAS_EXTRA ] },
    { route : WEBHOTELS_ROUTE,        enableIfProductsOf : [ ProductType.WEB_HOTEL, ProductType.WEB_HOTEL_EXTRA ] },
    { route : VIRTUALSERVERS_ROUTE,   enableIfProductsOf : [ ProductType.VIRTUAL_SERVER, ProductType.VIRTUAL_SERVER_EXTRA ] },
    { route : EMAILS_ROUTE,           enableIfProductsOf : [ ProductType.EMAIL, ProductType.EMAIL_EXTRA ] },
    { route : WORDPRESS_ROUTE,        enableIfProductsOf : [ ProductType.WP ] },
    { route : WEBRTC_ROUTE,           enableIfProductsOf : [ ProductType.WEBRTC ] },
    { route : NET_ROUTE,              enableIfProductsOf : [ ProductType.NET ] },
    { route : OUTLET_ROUTE,           enableIfProductsOf : [ ProductType.OUTLET ] },
    { route : PREMIUM_DOMAINS_ROUTE,  enableIfProductsOf : [ ProductType.PREMIUM_DOMAIN ] },
    { route : IMAGES_ROUTE,           enableIfProductsOf : [ ProductType.PHOTO ] },
];

export const MENU_ITEMS : MenuItem[] = [
    { route : SHELLS_ROUTE,           title : T_NAV_SHELLS             },
    { route : ATLAS_ROUTE,            title : T_NAV_ATLAS,             },
    { route : WEBHOTELS_ROUTE,        title : T_NAV_WEBHOTELS,         },
    { route : VIRTUALSERVERS_ROUTE,   title : T_NAV_VIRTUALSERVERS,    },
    { route : EMAILS_ROUTE,           title : T_NAV_EMAIL,             },
    { route : DOMAINS_ROUTE,          title : T_NAV_DOMAINS,           },
    { route : WORDPRESS_ROUTE,        title : T_NAV_WP,                },
    { route : WEBRTC_ROUTE,           title : T_NAV_WEBRTC,            },
    { route : NET_ROUTE,              title : T_NAV_NET,               },
    { route : SOFTWARE_ROUTE,         title : T_NAV_SOFTWARE,               },
    { route : OUTLET_ROUTE,              title : T_NAV_OUTLET,               },
    // { route : PREMIUM_DOMAINS_ROUTE,  title : T_NAV_PREMIUM_DOMAIN,    },
    { route : IMAGES_ROUTE,           title : T_NAV_IMAGES,            },
    { route : CUSTOMER_SERVICE_ROUTE, title : T_NAV_CUSTOMER_SERVICE   },
    // { route : NEWSLETTERS_ROUTE,      title : T_NAV_NEWSLETTERS        },
];

/**
 * Required for the SSR server
 * This should especially include any React intended route that's also available
 * as a file inside the static folder, like `/index.html`.
 */
export const ALL_REACT_ROUTES : readonly string[] = [
    INDEX_FILE,
    ABOUT_ROUTE,
    INTRO_ROUTE,
    PRIVACY_POLICY_ROUTE,
    WEBHOTELS_ROUTE,
    WEBHOTELS_FOOTER_ROUTE,
    WEBHOTELS_EXTRA_ROUTE,
    SOFTWARE_ROUTE,
    SOFTWARE_FOOTER_ROUTE,
    WEB_HOTEL_EXTRA_ROUTE_FORMAT,
    EMAILS_EXTRA_PRODUCT_LIST_ROUTE,
    EMAILS_ROUTE,
    EMAILS_EXTRA_ROUTE_FORMAT,
    DOMAINS_ROUTE,
    DOMAIN_SALE_ROUTE_FORMAT,
    OUTLET_ROUTE,
    OUTLET_PRODUCT_ROUTE_FORMAT,
    VIRTUALSERVERS_ROUTE,
    VIRTUALSERVERS_PRODUCT_ROUTE,
    VIRTUALSERVERS_INTRO_ROUTE,
    VIRTUALSERVERS_EXTRA_ROUTE_FORMAT,
    VIRTUALSERVERS_EXTRA_ROUTE,
    VIRTUALSERVERS_FAQ_ROUTE,
    VIRTUALSERVER_FOOTER_ROUTE,
    SHELLS_ROUTE,
    SHELLS_EXTRA_ROUTE,
    SHELLS_FEATURE_LIST_ROUTE,
    SHELLS_FOOTER_ROUTE,
    ATLAS_ROUTE,
    ATLAS_EXTRA_ROUTE_FORMAT,
    CUSTOMER_SERVICE_ROUTE,
    CART_ROUTE,
    NEWSLETTERS_ROUTE,
    DATABASES_ROUTE,
    MY_INDEX_ROUTE,
    MY_CLIENT_LIST_ROUTE,
    MY_LOGIN_ROUTE,
    MY_CLIENT_ROUTE,
    MY_CLIENT_PAYMENT_LIST_ROUTE,
    MY_CLIENT_PAYMENT_ROUTE,
    MY_CLIENT_INVENTORY_LIST_ROUTE,
    MY_CLIENT_INVENTORY_ITEM_ROUTE,
    MY_CLIENT_INVOICE_LIST_ROUTE,
    MY_CLIENT_INVOICE_ROUTE,
    MY_CLIENT_ORDER_LIST_ROUTE,
    MY_CLIENT_ORDER_ROUTE,
    NEW_ORDER_ROUTE,
    TERMS_OF_USE_ROUTE,
    TERMS_OF_DELIVERY_ROUTE,
    COOKIES_ROUTE,
    WEBRTC_ROUTE,
    WORDPRESS_ROUTE,
    PREMIUM_DOMAINS_ROUTE,
    IMAGES_ROUTE,
    NET_ROUTE,
    LOGIN_INDEX_ROUTE,
    AGENT_FACEBOOK_ROUTE,
    INDEX_ROUTE,
];

export const GET_MY_CLIENT_ROUTE   = (clientId : string ) => `/my/clients/${q(clientId)}`;
export const GET_MY_CLIENT_PAYMENT_LIST_ROUTE   = (clientId : string ) => `/my/clients/${q(clientId)}/payments`;
export const GET_MY_CLIENT_INVENTORY_LIST_ROUTE = (clientId : string ) => `/my/clients/${q(clientId)}/inventory`;
export const GET_MY_CLIENT_INVOICE_LIST_ROUTE   = (clientId : string ) => `/my/clients/${q(clientId)}/invoices`;
export const GET_MY_CLIENT_INVOICE_ROUTE   = (clientId : string, invoiceId: string) => `/my/clients/${q(clientId)}/invoices/${q(invoiceId)}`;

export const GET_API_CLIENT_INVOICE_PAYMENT_REDIRECT_ROUTE   = (clientId : string, invoiceId: string) => `/clients/${q(clientId)}/invoices/${q(invoiceId)}/payment-redirect`;
export const GET_API_CLIENT_INVOICE_PAYMENT_UPDATE_ROUTE   = (clientId : string, invoiceId: string) => `/clients/${q(clientId)}/invoices/${q(invoiceId)}/payment-update`;

export const GET_MY_CLIENT_ORDER_LIST_ROUTE     = (clientId : string ) => `/my/clients/${q(clientId)}/orders`;
export const GET_MY_CLIENT_INVENTORY_ITEM_ROUTE   = (clientId : string, inventoryItem: string) => `/my/clients/${q(clientId)}/inventory/${q(inventoryItem)}`;
export const GET_MY_CLIENT_PAYMENT_ITEM_ROUTE   = (clientId : string, paymentId: string) => `/my/clients/${q(clientId)}/payments/${q(paymentId)}`;

//export const getAuctionCategoryRoute  = (c : CategoryType) => `/auction/c/${q(c.toLowerCase())}`;
export const getProductItemRoute      = (c : string) => `/product/i/${q(c)}`;

export const getSeoOgImageUrl = (data: SendanorSeoImageData, name: string) : string => `/api/og/${encodeSendanorSeoImageData(data)}/${q(name)}`;

function q (value: string) : string {
    return encodeURIComponent(value);
}
