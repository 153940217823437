

import { parseNonEmptyString } from "../../../../io/hyperify/core/types/String";
import { ProductPriceType } from "../../../../io/hyperify/core/store/types/product/ProductPriceType";

export const FRONTEND_DEFAULT_LANGUAGE = 'fi';

export const PUBLIC_URL = parseNonEmptyString(process.env.PUBLIC_URL) ?? parseNonEmptyString(process.env.REACT_APP_PUBLIC_URL) ?? 'http://localhost:3000';
export const PRICE_UPDATE_INTERVAL_IN_MS = 3 * 60 * 1000;

export const DEFAULT_SELECTED_PRICE_TYPE = ProductPriceType.YEARLY;
export const DEFAULT_SELECTED_PRICE_TYPE_FOR_SHELL = DEFAULT_SELECTED_PRICE_TYPE;
export const DEFAULT_SELECTED_PRICE_TYPE_FOR_WEBRTC = DEFAULT_SELECTED_PRICE_TYPE;
export const DEFAULT_SELECTED_PRICE_TYPE_FOR_VPS = DEFAULT_SELECTED_PRICE_TYPE;
export const DEFAULT_SELECTED_PRICE_TYPE_FOR_OUTLET = ProductPriceType.ONCE;
export const DEFAULT_SELECTED_PRICE_TYPE_FOR_DATABASE = DEFAULT_SELECTED_PRICE_TYPE;
export const DEFAULT_SELECTED_PRICE_TYPE_FOR_EMAIL = DEFAULT_SELECTED_PRICE_TYPE;
export const DEFAULT_SELECTED_PRICE_TYPE_FOR_WEBHOTELS = DEFAULT_SELECTED_PRICE_TYPE;
export const DEFAULT_SELECTED_PRICE_TYPE_FOR_DOMAINS = DEFAULT_SELECTED_PRICE_TYPE;
export const DEFAULT_SELECTED_PRICE_FOR_ATLAS = DEFAULT_SELECTED_PRICE_TYPE;
export const DEFAULT_SELECTED_PRICE_TYPE_FOR_SOFTWARE = ProductPriceType.YEARLY;
