import { ReactNode } from "react";
import { PhoneNumberUtils } from "../../../../../io/hyperify/core/PhoneNumberUtils";

export interface PhoneLinkProps {
    readonly className ?: string;
    readonly phone      : string;
    readonly children  ?: ReactNode;
}

export function PhoneLink (props : PhoneLinkProps) {

    const className = `phone-link ${ props?.className ?? '' }`;
    const phone      = props.phone;
    const children   = props?.children;

    if ( children && !(children as any)?.type ) {

        return (
            <a className={className}
               href={ PhoneNumberUtils.getTelLink(phone) }>{ children }</a>
        );

    } else {

        return (
            <a className={className}
               href={ PhoneNumberUtils.getTelLink(phone) }>{ PhoneNumberUtils.getTelLabel(phone) }</a>
        );

    }

}
